import React from 'react';
import './Features.css';
import repseLogo from '../../assets/repse.png'; 
import { FaExternalLinkAlt, FaEnvelope, FaPhoneAlt } from "react-icons/fa";

const Features = () => {
  return (
    <div className='gpt3Features' id='features'>
      <div className='gpt3FeaturesContainer'>
        <div className='featureBox'>
          <div className='featureContent'>
            <h2>Dejaremos que te concentres en tus metas</h2>
            <p>
              Las empresas que deseen prestar servicios como los que ofrecemos nosotros deben contar con un <strong>Permiso / Registro vigente en el REPSE</strong> para garantizar que están al corriente de sus obligaciones laborales, fiscales y de seguridad social.
            </p>
          </div>

          <div className="registration-section">
            <p className="registration-title">Aviso de registro N. / Fecha de aviso de registro</p>
            <p className="registration-info"><strong>AR29518</strong> / <span>2021-09-03</span></p>
            <a href="https://repse.stps.gob.mx/app/resultado/eVBtdlNoUGlMeTNoelF3SVJtdmlPMkdpeCtWTU4yRC9IM1NkbEpRQlFFWkZrU2wrdmh2SFFEeXRnbjkvYVl2NA==_fVh3eyLZoq" 
               target="_blank" 
               rel="noopener noreferrer" 
               className="repse-button">
              <FaExternalLinkAlt className="button-icon" />
              Ver Registro en REPSE
            </a>
          </div>

          <div className='contact-section'>
            <div className='contact-info'>
              <h3>Contacto:</h3>
              <div className="contact-item">
             
                <div className="contact-detail">
                  <FaPhoneAlt className="contact-icon" />
                  <p>449 1113024</p>
                </div>
              </div>
              <div className="contact-item">
               
                <div className="contact-detail">
                  <FaPhoneAlt className="contact-icon" />
                  <p>449 2851615</p>
                </div>
              </div>
              <div className="contact-item">
                <div className="contact-detail">
                  <FaEnvelope className="contact-icon" />
                  <p>prostaffse@gmail.com</p>
                </div>
              </div>
              <div className="contact-item">
                <div className="contact-detail">
                  <FaEnvelope className="contact-icon" />
                  <p>73arodriguez@gmail.com</p>
                </div>
              </div>
            </div>
          </div>

          <div className="repseLogoContainer">
            <img src={repseLogo} alt="REPSE logo" className='logo' />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
