import React from 'react';
import './WhatGPT3.css';
import { FaCheckCircle } from 'react-icons/fa';
import teamImage from '../../assets/condomio.png'; // Asegúrate de que la ruta sea correcta

const WhatGPT3 = () => {
  return (
    <div className="condominioSection">
      <div className="condominioHeader">
        <h2>Servicio de Administración de Condominios</h2>
        <p>Ponemos a tu disposición personal especializado para ejecutar las siguientes funciones:</p>
      </div>
      <div className="condominioContent">
        <div className="condominioImage">
          <img src={teamImage} alt="Team" />
        </div>
        <div className="condominioText">
          <div className="condominioCard">
            <h3>Administración de Condominios</h3>
            <ul>
              <li><FaCheckCircle className="icon" /> Recaudación y registro de cuotas ordinarias y extraordinarias de mantenimiento.</li>
              <li><FaCheckCircle className="icon" /> Expedir a los condóminos los estados de cuenta del pago de cuotas.</li>
              <li><FaCheckCircle className="icon" /> Recuperación de cartera vencida.</li>
              <li><FaCheckCircle className="icon" /> Informes del estado que guarda la administración del condominio.</li>
              <li><FaCheckCircle className="icon" /> Promover la cultura condominal.</li>
              <li><FaCheckCircle className="icon" /> Control y supervisión de servicios externos.</li>
              <li><FaCheckCircle className="icon" /> Atención a condóminos.</li>
              <li><FaCheckCircle className="icon" /> Control de gastos.</li>
              <li><FaCheckCircle className="icon" /> Control de pago de servicios y proveedores.</li>
              <li><FaCheckCircle className="icon" /> Ejecución de acuerdos de la asamblea y del comité de administración.</li>
              <li><FaCheckCircle className="icon" /> Vigilancia del cumplimiento del reglamento del condominio.</li>
              <li><FaCheckCircle className="icon" /> Organización de asambleas ordinarias y extraordinarias de condóminos.</li>
            </ul>
          </div>
          <div className="condominioCard">
            <h3>El servicio incluye:</h3>
            <ul>
              <li><FaCheckCircle className="icon" /> Una persona para apoyo administrativo de forma presencial en los días y horarios que requiera atención el condominio, y atención telefónica fuera de esos horarios.</li>
              <li><FaCheckCircle className="icon" /> Herramienta para administración y control de saldos, pagos y estado de cuenta de los pagos de cuotas ordinarias y extraordinarias de condóminos.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatGPT3;
