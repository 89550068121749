import React from "react";
import "./CTA.css";
import logo1 from '../../assets/logo1.png'; // Actualiza las rutas según corresponda
import logo2 from '../../assets/logo2.png';
import logo3 from '../../assets/logo3.png';
import logo4 from '../../assets/logo4.png';
import logo5 from '../../assets/logo5.png';

const CTA = () => {
  return (
    <div className="clientesSection">
      {/* <div className="waveTop"></div> Nueva curva en la parte superior */}
      <h2>Nuestros Clientes</h2>
      <div className="clientesGrid">
        <img src={logo1} alt="Cliente 1" className="clienteLogo" />
        <img src={logo2} alt="Cliente 2" className="clienteLogo" />
        <img src={logo3} alt="Cliente 3" className="clienteLogo" />
        <img src={logo4} alt="Cliente 4" className="clienteLogo" />
        <img src={logo5} alt="Cliente 5" className="clienteLogo" />
      </div>
      <div className="waveBottom"></div> {/* Nueva curva en la parte inferior */}
    </div>
  );
};

export default CTA;
