import React, { useState } from "react";
import { FaUsers, FaMoneyCheckAlt, FaSearch, FaBuilding, FaBroom, FaLeaf, FaClipboardCheck } from "react-icons/fa";
import "./Brand.css";

import repseLogo from '../../assets/repse.png';
const servicesData = [
  {
    title: "Reclutamiento y Head Hunting",
    description: (
      <>
        <p>Reclutamiento y Selección de Personal de todos los niveles:</p>
        <ul>
          <li>Personal Operativo</li>
          <li>Personal Administrativo</li>
          <li>Gerencial y Mandos Medios</li>
        </ul>
        <p><em>*Ofrecemos los mejores precios y condiciones que ofrece el mercado.</em></p>
      </>
    ),
    icon: <FaUsers />,
    image: repseLogo,
  },
  {
    title: "Administración de Nómina y Remuneraciones",
    description: (
      <>
        <ul>
          <li>Elaboración & Revisión de Nominas</li>
          <li>Calculos SUA e ISN</li>
          <li>Auditoría de Nominas & Pagos</li>
          <li>Headcount</li>
        </ul>

      </>
    ),
    icon: <FaMoneyCheckAlt />, image: repseLogo,
  },
  {
    title: "Diagnóstico Organizacional y Medición de Clima Laboral",
    description: "Diagnóstico y encuestas de clima laboral con propuestas de mejora, y análisis de riesgos psicosociales.",
    icon: <FaSearch />, image: repseLogo,
    longDescription: (
      <>
        <p>Diagnóstico y encuestas de clima laboral con propuestas de mejora.</p>
        <p>Apoyo para identificar y analizar los factores de riesgo psicosocial y evaluar el entorno organizacional con la elaboración de informes de resultados.</p>
        <br /><p><strong>Precio del Servicio:</strong></p>
        <ul>
          <li><strong>Aplicación de Encuestas:</strong> La aplicación de encuestas se hace de forma presencial a grupos de entre 20 y 30 personas.</li>
          <li><strong>Costo del Diagnóstico:</strong> La mejor opción del mercado.</li>
        </ul>
      </>
    ),
  },
  {
    title: "NOM 035 STPS Riesgos Psicosociales",
    description: "Apoyo para identificar y analizar los factores de riesgo psicosocial y evaluar el entorno organizacional y elaboración de informe de Resultados.",
    icon: <FaClipboardCheck />, image: repseLogo,
    longDescription: (
      <>
        <br /><p><strong>Precio del Servicio:</strong></p>
        <ul>
          <li><strong>Aplicación de Encuestas:</strong> La aplicación de encuestas se hace de forma presencial a grupos de entre 20 y 30 personas.</li>
          <li><strong>Costo del Diagnóstico:</strong> La mejor opción del mercado.</li>
        </ul>
      </>
    ),
  },
  {
    title: "PLANTILLA LABORAL IN HOUSE",
    description: "Inplants para la gestión de procesos administrativos como RRHH, Contabilidad, Compras, Almacén, Logística e IT.",
    icon: <FaUsers />, image: repseLogo,
  },
  {
    title: "Administración de Condominios",
    description: "Ofrecemos un servicio integral para la administración de condominios, gestionando cuotas, gastos y más.",
    icon: <FaBuilding />, image: repseLogo,
    
  },
  {
    title: "Mantenimiento Áreas Verdes",
    description: (
      <>
        <ul>
        <li>Poda y arreglo de jardines.
        </li>
        <li>Poda de plantas y arbustos pequeños.
        </li>
        <li>Deshierbe y poda jardineras.</li>
          <li>Riego de áreas verdes.</li>
          <li>Aplicación de fertilizante y plaguicidas.</li>
          <li>Plantación de pasto, arbustos y/o plantas de jardín por reposición especies dañadas.
          </li>
        </ul><br />
        <p><em>*Ofrecemos los mejores precios y condiciones que ofrece el mercado.</em></p>
      </>
    ),
    icon: <FaBroom />, image: repseLogo,
 
  },
  {
    title: "Servicios de Limpieza",
    description: (
      <>
        <ul>
          <li>Limpieza de inmuebles y áreas comunes.</li>
          <li>Planes de Manejo de Residuos</li>
          <li>Elaboración  de Manifiestos</li>
          <li>Bitácora de generación de Residuos</li>
        </ul><br />
        <p><em>*Ofrecemos los mejores precios y condiciones que ofrece el mercado.</em></p>
      </>
    ),
    icon: <FaBroom />, image: repseLogo,
 
  },
];
const Brand = () => {
  const [expandedService, setExpandedService] = useState(null);

  return (
    <section className="services-section">
      <h2>Nuestros Servicios</h2>
      <div className="services-grid">
        {servicesData.map((service, index) => (
          <div key={index} className={`service-item ${expandedService === index ? 'expanded' : ''}`}>
            <div className="icon">{service.icon}</div>
            <div className="service-content">
              <h3>{service.title}</h3>
              <div className="description">{service.description}</div>
              {expandedService === index && <p className="long-description">{service.longDescription}</p>}
            </div>
            {service.longDescription && (
              <button onClick={() => setExpandedService(expandedService === index ? null : index)}>
                {expandedService === index ? "Ver menos" : "Ver más"}
              </button>
            )}
            <div className="service-logo-container">
              {service.image && <img src={service.image} alt="REPSE Logo" className="service-logo" />}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Brand;