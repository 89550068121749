import React, { useState } from "react";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import "./Navbar.css";
import Logo from "../../assets/logo.svg";

const Menu = () => (
  <>
    {/* <p>
      <a href="#home">Home</a>
    </p>
    <p>
      <a href="#whatGpt3">Servicios</a>
    </p>
    <p>
      <a href="#possibility">Open AI</a>
    </p>
    <p>
      <a href="#features">REPSE</a>
    </p> */}
    {/* <p>
      <a href="#blog">Blog</a>
    </p> */}
  </>
);

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className="gpt3Navbar">
      <div className="gpt3NavbarLinks">
        <div className="gpt3NavbarLinksLogo">
          <img src={Logo} alt="logo" />
        </div>
        <div className="gpt3NavbarLinksContainer">
          <Menu />
        </div>
      </div>
     
      <div className="gpt3NavbarMenu">
        {toggleMenu ? (
          <RiCloseLine
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="gpt3NavbarMenuContainer scale-up">
            <div className="gpt3NavbarMenuContainerLinks">
              <Menu />
              
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
