import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footerContent">
        <div className="footerText">
          <h2>¡SOMOS TU SOLUCIÓN!</h2>
          <p>CONTACTANOS</p>
        </div>
        <div className="footerContactInfo">
          <div className="contactItem">
            <i className="fas fa-phone-alt"></i>
            <p>4491113024</p>
          </div>
          <div className="contactItem">
            <i className="fas fa-envelope"></i>
            <p>ProstaffSE@gmail.com</p>
          </div>
          <div className="contactItem">
            <i className="fas fa-globe"></i>
            <p>https://prostaffse.com</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
