import React from "react";
import "./Header.css";
import People from '../../assets/repse.png';
import AI from '../../assets/people2.png';

const Header = () => {
  return (
    <div className="gpt3Header sectionPadding" id="home">
      <div className="gpt3HeaderContent">
        <h1 className="gradientText">NOSOTROS</h1>
        <p>Somos una empresa de soporte que resuelve los retos administrativos y gestiona servicios en tu organización.</p>
        <p>Contamos con más de 15 años de experiencia en gestión de RH y procesos administrativos.</p>
        <p>Registrados en el REPSE, cumplimos con obligaciones fiscales, laborales y de seguridad.</p>
        <p>Estamos enfocados en resolver los problemas de nuestros clientes.</p>
        <div className="gpt3HeaderContentPeople">
          <img alt="people" src={People} />
         
        </div>
      </div>
      <div className="gpt3HeaderImage">
        <img src={AI} alt="office" />
      </div>
    </div>
  );
};

export default Header;
